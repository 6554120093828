import React from 'react'
import blooddonation from "../images/blood donation.jpg";
import chess from "../images/chess material donation.jpg"
import tarurakhi from "../images/TREE PLANTATION.jpg";
import taru2022 from "../images/taru 2022.jpg";

import treeplantationnew from "../images/tree plantation new.jpg";
import annualfootbalmatch2024 from  "../images/Football match 2024 misaa.jpg";
import Blanketdistribution2024 from "../images/blanket distribution 2024.jpg";

import annualgettogether from "../images/Annual get together 2024.jpg"

function Gallerypage() {
    return (
        <div>
           

           
           

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Blood-donation'>
                                        <div className="card">
                                            <img src={blooddonation} className="card-img-top" alt="blooddonation" />
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Blood donation</h5>
                                              

                                                <div className="btn-grad15" type="submit"><small>View Photo</small> </div>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3 me-3">
                                    <a href='chess-material-donation'>
                                        <div className="card">
                                            <img src={chess} className="card-img-top" alt="chess" />
                                            <div className="card-body">
                                                <h6 style={{ color: "black" }} className="card-title mb-1"><b>Chess material donation</b></h6>
                                               

                                                <a href="chess-material-donation" className="btn-grad15"><small>View Photo</small> </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                              

                            </div>
                            
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Rakhi'>
                                        <div className="card">
                                            <img src={tarurakhi} className="card-img-top" alt="tarurakhi"/>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Taru Rakhi Bandhan</h5>
                                              
                                                <div className="btn-grad15" type="submit"><small>View Photo</small> </div>

                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3 me-3">
                                    <a href='miscellaneous'>
                                        <div className="card">
                                            <img src={taru2022} className="card-img-top" alt="Miscellaneous"/>
                                            <div className="card-body">
                                                <h6 style={{ color: "black" }} className="card-title mb-1"><b>Miscellaneous Photo
                                                </b></h6>


                                                <a href="miscellaneous" className="btn-grad15"><small>View Photo</small> </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                              

                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>



            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='treeplantation-in-school-premises'>
                                        <div className="card">
                                            <img src={treeplantationnew} className="card-img-top" alt="treeplantation"/>
                                            <div className="card-body">
                                                <h6 style={{ color: "black" }} className="card-title mb-1">Tree Plantation in School Premises</h6>
                                               

                                                <div className="btn-grad15" type="submit"><small>View Photo</small> </div>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3 me-3">
                                    <a href='Footbalmatch-2024'>
                                        <div className="card">
                                            <img src={annualfootbalmatch2024} className="card-img-top" alt="Annula Football Match 2024 M.I.S.A.A XI vs. M.I.S XI"/>
                                            <div className="card-body">
                                                <h6 style={{ color: "black" }} className="card-title mb-1"><b>Annula Football Match 2024 M.I.S.A.A XI vs. M.I.S XI</b></h6>
                                  

                                                <a href="Footbalmatch-2024" className="btn-grad15"><small>View Photo</small> </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                              

                            </div>

                            
                            
                        </div>
                      
                        <div className="col-md-6 mt-3">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Annualget-together2024'>
                                        <div className="card">
                                            <img src={annualgettogether} className="card-img-top" alt="annualgettogether"/>
                                            <div className="card-body">
                                                <h6 style={{ color: "black" }} className="card-title mb-1"> Annual Get-Together, Annual Re-union M.I.S.A.A Member, Musical fest & Gala Dinner 2024 (School Campus)</h6>
                                               

                                                <div className="btn-grad15" type="submit"><small>View Photo</small> </div>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3 me-3">
                                    <a href='Blanket-distribution2024'>
                                        <div className="card">
                                            <img src={Blanketdistribution2024} className="card-img-top" alt="chess" />
                                            <div className="card-body">
                                                <h6 style={{ color: "black" }} className="card-title mb-1">Blanket Distribution to The poor and Destitute Children of Bharat Sebasram Sangha, Chuanpur, Berhampore.</h6>
                                            

                                                <a href="Blanket-distribution2024" className="btn-grad15"><small>View Photo</small> </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                              

                            </div>

                            
                            
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </div>
    )
}

export default Gallerypage