import React from 'react'

import { Link } from "react-router-dom";
import SrElsyann from "../images/Sister-Cilily_final-1200x1200.jpg";
import Header from '../inc/Header';

function Cicily() {
    return (
        <div>
            <Header/>
            <br />
            <br />
         <br/>
         <br/>
         <br />
         <br />
            <div className='bestberhamporeschool'>
            <div className="album py-1">
                <div className="container mt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Sr. Cicily<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg> </li>
                        </ol>
                    </nav>
                </div>
            </div>
            </div>
            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <img src={SrElsyann} className="card-img-top" alt="Sr. Elsyann" />

                            </div>
                        </div>
                        <div className="col-md-8 mt-3">


                            <div className="card-body">
                                <h5 className="card-title textjustify mb-3">Loving greetings to each and every member of MISAA, and hearty congratulations to the office bearers for successfully establishing the website for speedy growth of activities of MISAA. They also deserve great appreciation for their sincere efforts they made for fund raising. In this effort they did generous co-operation from one of our MISAA members. We are thankful to them.

                                </h5>
                                <h5 className='textjustify mb-3'>May God our loving Father do continue to reward your ceaseless effort and for your thoughtfulness for the less privileged ones in our society. Never get tired of doing right, yes doing good to others.</h5>
                                <h5 >Prayerful loving wishes.</h5>
                                <div className="d-flex justify-content-end align-items-center mb-0">

                                <h5> <b>Sister Cicily</b></h5> <br/>
                                </div>
                                <div className="d-flex justify-content-end align-items-center mb-0">

                                <h5><b>SMI</b></h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br/>


        </div>
    )
}

export default Cicily