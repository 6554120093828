import React from 'react'

import { Link } from "react-router-dom";
import SrJacinthaprincipal from "../images/principal-1-1200x1200.png";

import Header from '../inc/Header';
import ScrollToTop from "react-scroll-to-top";
function Jacintha() {
    return (
        <div>
            <Header/>
            <br />
            <br />
         <br/>
         <br/>
         <br />
         <br />
         <ScrollToTop smooth top="100" color="light"/>
            <div className='bestberhamporeschool'>
            <div className="album py-1">
                <div className="container mt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Sr. Jacintha Manjaly<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg> </li>
                        </ol>
                    </nav>
                </div>
            </div>
            </div>
            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <img src={SrJacinthaprincipal} className="card-img-top" alt="SrJacinthaprincipal" />

                            </div>
                        </div>
                        <div className="col-md-8 mt-3">


                            <div className="card-body">
                                <h5 className="card-title textjustify mb-3">I am sure your memories of Mary Immaculate School, are fond ones. Class rooms,
corridors, friends, dedicated teachers and staff, and disciplined campus with high
spiritual ethos. These are the rarest gifts that M.I.S has contributed to your over-all
well being and still continue to impart for the generations to come.


                                </h5>
                                <h5 className='textjustify mb-3'>It is always said, “The Old is Gold”, so are old friends with whom we had shared
everything over decades. The modern digital world reminds us that our classmates
are irreplaceable. Mary Immaculate School Alumni Association, the official and
only ex student association of M.I.S gives you the best opportunity for everyone to
stay connected. The social programs they undertake, the annual get- together, will
help you to re-establish your relationship with your own peers and your beloved
school M.I.S and get informed with the current events, through updates from
M.I.S.A.A website and social media handles.
</h5>
<h5 className='textjustify'>What a privilege for me to be part of such an amazing organization like M.I.S.A.A,
filled with such amazing people around. I extend my full co-operation and support
in all their effort to help humanity.
</h5>
<h5>I Wish them God’s Blessings and wonderful years ahead !</h5>
                                <h5 >Prayerful loving wishes.</h5>
                                <div className="d-flex justify-content-end align-items-center mb-0">

                                <h5> <b>Sr. Jacintha Manjaly</b></h5> <br/>
                                </div>
                                <div className="d-flex justify-content-end align-items-center mb-0">

                                <h5><b>Principal M.I.S</b></h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br/>


        </div>
    )
}

export default Jacintha