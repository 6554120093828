import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'
import Header from '../inc/Header';









function Blanketdis2024() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(258);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [programs, setPrograms] = useState([])
    const navigate = useNavigate()

    const programsCollectionRef = collection(db, "Blanket Distribution to The poor and Destitute Children of Bharat Sebasram Sangha");
    useEffect(() => {

        const getPrograms = async () => {
            const data = await getDocs(programsCollectionRef);
            setPrograms(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getPrograms()
    }, [])
    return (

        <>
            <Header />
            <br />
            <br />

            <br />
            <br />



            <div className='bestberhamporeschoolmis'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/Gallery" style={{ color: "darkblue" }} >Back</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Blanket Distribution to The poor and Destitute Children of Bharat Sebasram Sangha, Chuanpur, Berhampore.

                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />

            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {programs.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((thelovebirdsicecream) => {
                            return (
                                <div className="col-md-4 mt-3" >
                                    <div className='card'>
                                        <div className='hoverimage1'>
                                            <img className="card-img-top image2" src={thelovebirdsicecream.img} alt={thelovebirdsicecream.title} />
                                            <div className="overlay1"> {thelovebirdsicecream.title}</div>
                                        </div>
                                        <div className="card-body">
                                            <center>
                                                <h6 className="mt-" style={{color:"darkBlue"}}> {thelovebirdsicecream.title}</h6>
                                            </center>
                                            <div className="d-flex justify-content-between align-items-center">
                                            </div>
                                        </div>

</div>
                                    </div>
                                    )
                        })}
                                </div>


                </div>
                </div>
                <br />
                <br />

            </>

            );
}

            export default Blanketdis2024;



