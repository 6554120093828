import React from 'react'
import {Link} from 'react-router-dom';
import bankdetails from "../images/bank-details.png";

import ScrollToTop from "react-scroll-to-top";
import Header from '../inc/Header';
function Aboutus() {
  return (
    <div>
        <Header/>
       <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="light"/>
        <div className='bestberhamporeschool'>
            <div className="album py-1">
                <div className="container mt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">About us</li>
                        </ol>
                    </nav>
                </div>
            </div>
            </div>

       <div className="album py-3">
        <div className="container">
          
        
            <h2><b>About us</b></h2>
            <br/>
            <h5 className='textjustify'>Even before M.I.S.A.A was born, a bunch of enthusiastic past pupils of M.I.S, under the active patronage of Father Sebastian Kazipala, Sr.Cicily and Sr. Lettice, often collected at the Catholic church. We undertook various social causes like, FEEDING THE ORPHANS, ORGANIZING BLOOD DONATION CAMPS, ARRANGING FREE MEDICAL CAMPS FOR THE POOR, PLANTING SAPLINGS, VISITING MOTHER HOUSE AND PAYING TRIBUTE TO MOTHER TERESA and also ORGANIZING A GET TOGETHER OF PAST PUPILS ON THE 9th OF NOVEMBER 2010 in the Catholic Church itself.</h5>
            <br/>
            <br/>
            <h2><b>Bank Details of MISAA for New Membership and Renewals</b></h2>
            <p className='textjustify'>Please do your RENEWALS (800/- INR GENERAL), if not done already. MISAA also appeals to all its members to opt for LIFE Membership, (10,000/- INR) if possible. It will help the organization to mobilize funds, as also resolve members from doing it repeatedly year after year…</p>
            <p>M.I.S.A.A. BANK DETAILS :-</p>
            Mary Immaculate School Alumni Association. <br/>
UNION BANK OF INDIA. <br/>

Account No. 548202010010629.<br/>
IFSC CODE : UBIN0554821.<br/>
SWIFT CODE : UBININBBOCL

     <br/>
     <br/>
     <img src={bankdetails} height="192px" width="160px" alt='bank-details'/>
        </div>
     
      </div>
      <br/>
      <br/>
    </div>
  )
}

export default Aboutus