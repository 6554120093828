
import React from 'react'


import blanketdisrtibution from "../images/Blanket Distribution 26th December 2024.jpeg";


function UpdateE() {
    return (
        <div>
            <div className="album py-3">
                <div className="container">
                    <div className="row">

                        <div className="col-md-4 mt-2">
                            <br />
                            <br />
                            <br />
                            <a href='CompleteEvent'>
                                <div className='btn-grad134'> Completed Events Bulletin</div>
                            </a>
                        </div>
                        <div className="col-md-8">
                            <div className='schoolberhampore'>
                                <h2 className='blink mt-2' >M.I.S.A.A <b>Upcoming Events Bulletin</b> :</h2>
                                <hr />
                            </div>
                            <div className="card" >
                                <div class="card-header">
                                    <center> <h3 style={{ color: "red" }} className='blink'></h3> </center></div>
                                {/* <img src={blanketdisrtibution} className="card-img-top mb-2" alt="blanketdisrtibution26thdecember2024"/> */}
                                {/* <img src={misaaelection2024} className="card-img-top" alt="misaaelection2024"/> */}
                                <div className="card-body">
                                    {/* <p style={{color:"grey"}} className='mb-0 mt-0'>Blanket Distribution to Orphan Children of Bharat Sevasram, Chuanpur, Berhampore. 26th December 2024, 03:00 PM</p> */}
                                </div>
                            </div>

                        </div>
                    </div >
                </div >
            </div >
        </div >
    )
}

export default UpdateE
