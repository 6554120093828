import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {Link} from 'react-router-dom';
import { db } from '../../firebase'

import ScrollToTop from "react-scroll-to-top";
import Header from '../inc/Header';




function Misc() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(50);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [member, setMember] = useState([])
    const navigate = useNavigate()

    const memberCollectionRef = collection(db, "Miscellaneous Photo");
    useEffect(() => {

        const getMember = async () => {
            const data = await getDocs(memberCollectionRef);
            setMember(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getMember()
    }, [])
    return (

        <>
            <Header />

          
            <br />
            <br />
            <br />
            <br/>
            <br/>
            <br />
         
            <ScrollToTop smooth top="100" color="grey"/>

            <div className='bestberhamporeschoolmis'>
            <div className="album py-1">
                <div className="container mt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/Gallery" style={{ color: "darkblue" }} >Back</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Miscellaneous

                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            </div>
          
           
         
        <br/>
            <div className="album py-2">

                <div className="container">

                    <div className="row">

                        <div className="col-md-6">

                            <div className="card-body">
                                <input type="text" className="form-control" placeholder="Search.. " name="location"
                                    onChange={(e) => {
                                        setsearchTerm(e.target.value);
                                    }}
                                />
                                <div className="d-flex justify-content-between align-items-center">




                                </div>


                            </div>


                        </div>

                    </div>

                </div>
            </div>
            <div className="album py-4">

                <div className="container">

                    <div className="row">
                        {member.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase()) 
                            


                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((misaa) => {
                            return (

                                <div className="col-md-4">
                                    <div className="card mb-4 border-White box ">
                                        <img className="card-img-top" src={misaa.img} alt={misaa.name} />
                                        <div className="card-body">
                                        <h5 style={{color:"darkBlue"}} className="card-text"> <b>{misaa.name}</b></h5>
                                          
                                  
                                            
                                            
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>

        </>

    );
}

export default Misc;